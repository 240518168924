import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { db, storage } from '../firebase-config'; 
import { collection, doc, getDoc, addDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import './ProductForm.css';
import { useToast } from '../context/ToastContext';
import { generateSlug } from '../utils/urlUtils';
import { v4 as uuidv4 } from 'uuid';

const ParentProductForm = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { productId } = useParams();
  const [product, setProduct] = useState({
    name: '',
    description: '',
    images: [],
    categories: [],
    gst: 0,
    hsn: '',
    weight: 0,
    slug: '',
  });
  const [imageFiles, setImageFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (productId) {
      const fetchParentProduct = async () => {
        try {
          const productRef = doc(db, 'parent_products', productId);
          const productDoc = await getDoc(productRef);
          if (productDoc.exists()) {
            const productData = productDoc.data();
            setProduct(productData);
          } else {
            console.error('Parent product not found');
            navigate('/admin/manage-products');
          }
        } catch (error) {
          console.error('Error fetching product:', error);
        }
      };

      fetchParentProduct();
    }
  }, [productId, navigate]);

  useEffect(() => {
    if (!product.slug && product.name) {
      setProduct(prev => ({
        ...prev,
        slug: generateSlug(product.name)
      }));
    }
  }, [product.name]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduct(prev => ({ ...prev, [name]: value }));
  };

  const handleCategoriesChange = (e) => {
    setProduct(prev => ({
      ...prev,
      categories: e.target.value.split(',').map(cat => cat.trim())
    }));
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImageFiles(prevFiles => [...prevFiles, ...files]);
  };

  const removeImage = (indexToRemove) => {
    setImageFiles(prevFiles => 
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
    setProduct(prev => ({
      ...prev,
      images: prev.images.filter((_, index) => index !== indexToRemove)
    }));
  };

  const uploadImages = async () => {
    if (imageFiles.length === 0) {
        return product.images || [];
    }

    setIsUploading(true);
    const uploadedUrls = [];
    const totalFiles = imageFiles.length;
    let completedUploads = 0;

    try {
        for (const file of imageFiles) {
            const fileExtension = file.name.split('.').pop();
            const uniqueFileName = `${uuidv4()}_${Date.now()}.${fileExtension}`;
            const storageRef = ref(storage, `product-images/${uniqueFileName}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            await new Promise((resolve, reject) => {
                uploadTask.on(
                    'state_changed',
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        const overallProgress = ((completedUploads * 100) + progress) / totalFiles;
                        setUploadProgress(Math.round(overallProgress));
                    },
                    reject,
                    async () => {
                        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                        uploadedUrls.push(downloadURL);
                        completedUploads++;
                        resolve();
                    }
                );
            });
        }

        return [...(product.images || []), ...uploadedUrls];
    } catch (error) {
        console.error('Error uploading images:', error);
        toast.error('Failed to upload one or more images');
        throw error;
    } finally {
        setIsUploading(false);
        setUploadProgress(0);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!product.hsn) {
        toast.error('HSN code is required');
        return;
      }

      const uploadedImageUrls = await uploadImages();
      const productData = {
        ...product,
        images: uploadedImageUrls,
      };

      if (productId) {
        await updateDoc(doc(db, 'parent_products', productId), productData);
        toast.success('Product updated successfully');
      } else {
        await addDoc(collection(db, 'parent_products'), productData);
        toast.success('Product added successfully');
      }
      navigate('/admin/manage-products');
    } catch (error) {
      console.error('Error saving product:', error);
      toast.error('Failed to save product');
    }
  };

  return (
    <div className="admin-container">
      <div className="page-header">
        <h2 className="admin-header-title">
          {productId ? 'Edit Parent Product' : 'Add New Parent Product'}
        </h2>
      </div>

      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <div className="form-section">
            <div className="form-group">
              <label>Product Name</label>
              <input
                type="text"
                name="name"
                value={product.name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label>URL Slug (Optional)</label>
              <input
                type="text"
                name="slug"
                value={product.slug}
                onChange={handleInputChange}
                placeholder="custom-url-slug"
              />
              <small>Leave empty to auto-generate from product name</small>
            </div>
            <div className="form-group">
              <label>Description</label>
              <textarea
                name="description"
                value={product.description}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          <div className="form-section">
            <div className="form-group">
              <label>Upload Images</label>
              <input 
                type="file" 
                accept="image/*" 
                multiple
                onChange={handleImageChange}
              />
              {isUploading && (
                <div className="upload-progress">
                  Uploading: {uploadProgress}%
                </div>
              )}
              <div className="image-preview-container">
                {product?.images?.map((url, index) => (
                  <div key={`existing-${index}`} className="image-preview-item">
                    <img src={url} alt={`Product ${index + 1}`} />
                    <button 
                      type="button" 
                      onClick={() => removeImage(index)}
                      className="remove-image"
                    >
                      ×
                    </button>
                  </div>
                ))}
                {imageFiles.map((file, index) => (
                  <div key={`new-${index}`} className="image-preview-item">
                    <img src={URL.createObjectURL(file)} alt={`New upload ${index + 1}`} />
                    <button 
                      type="button" 
                      onClick={() => removeImage(index)}
                      className="remove-image"
                    >
                      ×
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="form-section">
            <div className="form-group">
              <label>HSN Code *</label>
              <input
                type="text"
                name="hsn"
                value={product.hsn}
                onChange={handleInputChange}
                required
                placeholder="Enter HSN Code"
              />
            </div>
            <div className="form-group">
              <label>Product Weight (in grams)</label>
              <input
                type="number"
                name="weight"
                value={product.weight}
                onChange={handleInputChange}
                min="0"
                step="0.01"
                placeholder="Enter product weight"
              />
            </div>
            <div className="form-group">
              <label>GST (%)</label>
              <input
                type="number"
                name="gst"
                value={product.gst}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          <div className="form-section">
            <div className="form-group">
              <label>Categories (comma-separated)</label>
              <input
                type="text"
                name="categories"
                value={product.categories.join(', ')}
                onChange={handleCategoriesChange}
              />
            </div>
          </div>

          <div className="button-container">
            <button type="submit" className="btn-submit" disabled={isUploading}>
              {isUploading ? 'Uploading...' : productId ? 'Update Product' : 'Add Product'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ParentProductForm;
