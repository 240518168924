import React, { useState, useEffect } from 'react';
import { collection, query, where, orderBy, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase-config';
import './InventoryLogs.css';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';

const InventoryLogs = () => {
  const { productId } = useParams();
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [productName, setProductName] = useState('');
  const [skus, setSkus] = useState([]);
  const [selectedSku, setSelectedSku] = useState('all');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        // First get the product name and SKUs
        const productDoc = await getDoc(doc(db, 'parent_products', productId));
        if (productDoc.exists()) {
          const productData = productDoc.data();
          setProductName(productData.name);
          setSkus(productData.skus || []);
        }

        // Get all logs for this product
        const q = query(
          collection(db, 'inventory_logs'),
          where('productId', '==', productId),
          orderBy('timestamp', 'desc')
        );
        
        const querySnapshot = await getDocs(q);
        const logsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        setLogs(logsData);
      } catch (error) {
        console.error('Error fetching inventory logs:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchLogs();
  }, [productId]);

  const handleBack = () => {
    navigate(`/admin/product-details/${productId}`);
  };

  const filteredLogs = selectedSku === 'all' 
    ? logs 
    : logs.filter(log => log.skuId === selectedSku);

  if (loading) return <div>Loading logs...</div>;

  return (
    <div className="admin-container">
      <div className="page-header">
        <div className="header-left">
          <button onClick={handleBack} className="btn-back">
            <ArrowBack /> Back to Product Details
          </button>
          <h2 className="admin-header-title">Inventory Logs - {productName}</h2>
        </div>
        <div className="sku-filter">
          <select 
            value={selectedSku} 
            onChange={(e) => setSelectedSku(e.target.value)}
            className="sku-select"
          >
            <option value="all">All SKUs</option>
            {skus.map(sku => (
              <option key={sku.skuId} value={sku.skuId}>
                {sku.skuId} - {sku.color} {sku.size}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="table-container">
        <table className="admin-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>SKU ID</th>
              <th>Previous Stock</th>
              <th>New Stock</th>
              <th>Change</th>
              <th>Type</th>
              <th>Updated By</th>
              <th>Reference</th>
            </tr>
          </thead>
          <tbody>
            {filteredLogs.map(log => (
              <tr key={log.id}>
                <td data-label="Date">{new Date(log.timestamp.toDate()).toLocaleString()}</td>
                <td data-label="SKU ID">{log.skuId}</td>
                <td data-label="Previous Stock">{log.previousStock}</td>
                <td data-label="New Stock">{log.newStock}</td>
                <td data-label="Change" className={log.change > 0 ? 'positive-change' : 'negative-change'}>
                  {log.change > 0 ? `+${log.change}` : log.change}
                </td>
                <td data-label="Type">{log.type}</td>
                <td data-label="Updated By">{log.updatedBy}</td>
                <td data-label="Reference">{log.referenceId || '-'}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {filteredLogs.length === 0 && (
          <div className="no-data">No inventory changes recorded yet.</div>
        )}
      </div>
    </div>
  );
};

export default InventoryLogs; 