import React from 'react';
import { NavLink } from 'react-router-dom';
import useAdminRole from '../../../hooks/useAdminRole';
import { 
  Dashboard, 
  People, 
  Inventory, 
  ShoppingCart, 
  LocalShipping,
  KeyboardArrowRight
} from '@mui/icons-material';
import './Sidebar.css';

const Sidebar = ({ isOpen }) => {
  const { permissions } = useAdminRole();

  const navItems = [
    {
      path: '/admin',
      icon: <Dashboard />,
      label: 'Dashboard',
      permission: null
    },
    {
      path: '/admin/manage-users',
      icon: <People />,
      label: 'Users',
      permission: 'manage_users'
    },
    {
      path: '/admin/manage-products',
      icon: <Inventory />,
      label: 'Products',
      permission: 'manage_products'
    },
    {
      path: '/admin/manage-orders',
      icon: <ShoppingCart />,
      label: 'Orders',
      permission: 'manage_orders'
    },
    {
      path: '/admin/manage-inventory',
      icon: <LocalShipping />,
      label: 'Inventory',
      permission: 'manage_inventory'
    }
  ];

  return (
    <aside className={`sidebar ${isOpen ? 'expanded' : 'collapsed'}`}>
      <div className="sidebar-header">
        <h1 className="logo">Admin</h1>
      </div>
      <nav className="sidebar-nav">
        {navItems.map((item) => (
          (!item.permission || permissions.includes(item.permission)) && (
            <NavLink
              key={item.path}
              to={item.path}
              className={({ isActive }) => 
                `nav-item ${isActive ? 'active' : ''}`
              }
              end={item.path === '/admin'}
            >
              <span className="icon">{item.icon}</span>
              <span className="label">{item.label}</span>
              <KeyboardArrowRight className="arrow" />
            </NavLink>
          )
        ))}
      </nav>
    </aside>
  );
};

export default Sidebar; 