import React, { useEffect, useState } from 'react';
import { getDocs, collection, query, where, orderBy } from 'firebase/firestore';
import { db } from '../firebase-config';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import './AdminDashboard.css';
import useAdminRole from '../hooks/useAdminRole';

// Register ChartJS components
ChartJS.register(ArcElement, Tooltip, Legend);

const AdminDashboard = () => {
  const { role } = useAdminRole();
  const [pendingOrders, setPendingOrders] = useState(0);
  const [lowStockSkus, setLowStockSkus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [metrics, setMetrics] = useState({
    totalSales: 0,
    itemsSold: 0,
    averageOrderValue: 0
  });
  const [orderStatusData, setOrderStatusData] = useState({
    labels: [],
    datasets: []
  });
  const [timeFilter, setTimeFilter] = useState('all');

  const statusColors = {
    'Pending': '#ffa726',    // Orange
    'Packed': '#29b6f6',     // Light Blue
    'Shipped': '#66bb6a',    // Light Green
    'Delivered': '#43a047',  // Green
    'Cancelled': '#e53935',  // Red
  };

  const filterOrdersByTime = (orders, filter) => {
    const now = new Date();
    const today = new Date(now.setHours(0, 0, 0, 0));
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    
    switch (filter) {
      case 'today':
        return orders.filter(order => {
          const orderDate = order.orderDate?.toDate();
          return orderDate >= today;
        });
      case 'lastWeek':
        const lastWeek = new Date(today);
        lastWeek.setDate(lastWeek.getDate() - 7);
        return orders.filter(order => {
          const orderDate = order.orderDate?.toDate();
          return orderDate >= lastWeek;
        });
      case 'lastMonth':
        const lastMonth = new Date(today);
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        return orders.filter(order => {
          const orderDate = order.orderDate?.toDate();
          return orderDate >= lastMonth;
        });
      default:
        return orders;
    }
  };

  const fetchDashboardData = async () => {
    try {
      setLoading(true);
      
      // Fetch orders for metrics
      const ordersRef = collection(db, 'orders');
      const ordersSnapshot = await getDocs(ordersRef);
      let orders = ordersSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Sort orders by date
      orders.sort((a, b) => {
        const dateA = a.orderDate?.toDate() || new Date(0);
        const dateB = b.orderDate?.toDate() || new Date(0);
        return dateB - dateA;
      });

      // Filter orders based on time filter
      const filteredOrders = filterOrdersByTime(orders, timeFilter);

      // Calculate metrics
      const completedOrders = filteredOrders.filter(order => 
        order.status !== 'Cancelled'
      );

      const totalSales = completedOrders.reduce((sum, order) => 
        sum + (Number(order.totalAmount) || 0)
      , 0);

      const itemsSold = completedOrders.reduce((sum, order) => 
        sum + (order.items?.reduce((itemSum, item) => 
          itemSum + (item.selections?.reduce((selectionSum, selection) => 
            selectionSum + (Number(selection.quantity) || 0), 0) || 0), 0) || 0), 0);

      setMetrics({
        totalSales,
        itemsSold,
        averageOrderValue: completedOrders.length ? totalSales / completedOrders.length : 0
      });

      // Set pending orders - only show orders with Pending status
      const pending = filteredOrders.filter(order => 
        order.status === 'Pending'
      );
      setPendingOrders(pending.length);

      // Fetch low stock items
      const productsSnapshot = await getDocs(collection(db, 'parent_products'));
      const lowStockItems = [];

      productsSnapshot.docs.forEach(doc => {
        const product = doc.data();
        const productId = doc.id;

        product.skus?.forEach(sku => {
          if (sku.stock < 15) {
            lowStockItems.push({
              productId,
              productName: product.name,
              ...sku,
            });
          }
        });
      });

      setLowStockSkus(lowStockItems);

      // Calculate order status distribution
      const statusCounts = filteredOrders.reduce((acc, order) => {
        let status = order.status;
        if (!status || !statusColors[status]) {
          status = 'Unknown';
        }
        acc[status] = (acc[status] || 0) + 1;
        return acc;
      }, {});

      // Add this function to sort statuses in logical order
      const getStatusOrder = (status) => {
        const order = {
          'Pending': 1,
          'Packed': 2,
          'Shipped': 3,
          'Delivered': 4,
          'Cancelled': 5
        };
        return order[status] || 6;
      };

      // Prepare data for pie chart
      const labels = Object.keys(statusCounts).sort((a, b) => getStatusOrder(a) - getStatusOrder(b));
      console.log('Status Labels:', labels);
      console.log('Status Counts:', statusCounts);
      
      const data = labels.map(label => statusCounts[label]);
      const backgroundColor = labels.map(status => statusColors[status] || '#999');

      setOrderStatusData({
        labels,
        datasets: [{
          data,
          backgroundColor,
          borderColor: backgroundColor.map(color => color),
          borderWidth: 1,
        }]
      });

    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboardData();
  }, [timeFilter]);

  const handleTimeFilterChange = (filter) => {
    setTimeFilter(filter);
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        position: 'right',
        labels: {
          usePointStyle: true,
          padding: 15,
          font: {
            size: 11
          }
        }
      },
      layout: {
        padding: {
          left: 5,
          right: 5,
          top: 5,
          bottom: 5
        }
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            const label = context.label || '';
            const value = context.raw || 0;
            const total = context.dataset.data.reduce((a, b) => a + b, 0);
            const percentage = ((value / total) * 100).toFixed(1);
            return `${label}: ${value} (${percentage}%)`;
          }
        }
      }
    }
  };

  if (loading) {
    return <div className="loading">Loading dashboard data...</div>;
  }

  return (
    <div className="admin-dashboard">
      <div className="dashboard-header">
        <h2>Dashboard Overview</h2>
        <div className="time-filter-section">
          <button 
            className={`filter-btn ${timeFilter === 'all' ? 'active' : ''}`}
            onClick={() => handleTimeFilterChange('all')}
          >
            All Time
          </button>
          <button 
            className={`filter-btn ${timeFilter === 'today' ? 'active' : ''}`}
            onClick={() => handleTimeFilterChange('today')}
          >
            Today
          </button>
          <button 
            className={`filter-btn ${timeFilter === 'lastWeek' ? 'active' : ''}`}
            onClick={() => handleTimeFilterChange('lastWeek')}
          >
            Last 7 Days
          </button>
          <button 
            className={`filter-btn ${timeFilter === 'lastMonth' ? 'active' : ''}`}
            onClick={() => handleTimeFilterChange('lastMonth')}
          >
            Last 30 Days
          </button>
        </div>
      </div>

      <main className="dashboard-content">
        <div className="dashboard-cards">
          {role === 'admin' && (
            <>
              <div className="card-admin">
                <h3>Total Sales</h3>
                <p className="card-value">₹{metrics.totalSales.toLocaleString()}</p>
              </div>

              <div className="card-admin">
                <h3>Average Order Value</h3>
                <p className="card-value">₹{Math.round(metrics.averageOrderValue).toLocaleString()}</p>
              </div>
            </>
          )}

          <div className="card-admin">
            <h3>Items Sold</h3>
            <p className="card-value">{metrics.itemsSold}</p>
          </div>

          <div className="card-admin">
            <h3>Pending Orders</h3>
            <p className="card-value">{pendingOrders}</p>
          </div>
        </div>

        <div className="dashboard-charts">
          <div className="chart-section card-admin">
            <h3>Order Status Distribution</h3>
            <div className="pie-chart-container">
              <Pie data={orderStatusData} options={chartOptions} />
            </div>
          </div>

          <div className="inventory-section card-admin">
            <h3>Low Stock SKUs</h3>
            <table className="sku-table">
              <thead>
                <tr>
                   <th>SKU ID</th>
                  <th>Color</th>
                  <th>Size</th>
                  <th>Current Stock</th>
                </tr>
              </thead>
              <tbody>
                {lowStockSkus.map((sku) => (
                  <tr key={sku.skuId} className={sku.stock < 5 ? 'critical-stock' : 'low-stock'}>
             
                    
                    <td>{sku.skuId}</td>
                    <td>{sku.color}</td>
                    <td>{sku.size}</td>
                    <td className="stock-column">
                      <span className={`stock-badge ${sku.stock < 5 ? 'critical' : 'warning'}`}>
                        {sku.stock}
                      </span>
                    </td>
                  </tr>
                ))}
                {lowStockSkus.length === 0 && (
                  <tr>
                    <td colSpan="6" className="no-data">No low stock items</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AdminDashboard;
