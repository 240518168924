// src/admin/ManageProducts.js
import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase-config';
import { Link, useNavigate } from 'react-router-dom';
import './ManageProducts.css';
import { useToast } from '../context/ToastContext';
import useAdminRole from '../hooks/useAdminRole';
import SkeletonTable from '../components/Skeleton/SkeletonTable';

const ManageProducts = () => {
  const [parentProducts, setParentProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchParentProducts = async () => {
      try {
        const parentProductsCollection = collection(db, 'parent_products');
        const productSnapshot = await getDocs(parentProductsCollection);
        const productList = productSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setParentProducts(productList);
      } catch (error) {
        console.error('Error fetching parent products:', error);
        toast.error('Failed to load products');
      } finally {
        setLoading(false);
      }
    };

    fetchParentProducts();
  }, [toast]);

  const handleRowClick = (productId) => {
    navigate(`/admin/product-details/${productId}`);
  };

  return (
    <div className="admin-container">
      <div className="page-header">
        <h2 className="admin-header-title">Manage Products</h2>
        <Link to="/admin/add-parent-product" className="admin-action-btn btn-primary">
          Add New Product
        </Link>
      </div>

      {loading ? (
        <SkeletonTable 
          rows={5} 
          columns={4} // Matches your product table columns: Name, Description, Categories, Image
        />
      ) : (
        <div className="table-container">
          <table className="admin-table">
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Description</th>
                <th>Categories</th>
                <th>Image</th>
              </tr>
            </thead>
            <tbody>
              {parentProducts.length > 0 ? (
                parentProducts.map((product) => (
                  <tr 
                    key={product.id} 
                    onClick={() => handleRowClick(product.id)}
                    className="clickable-row"
                  >
                    <td>{product.name}</td>
                    <td>{product.description}</td>
                    <td>{product.categories.join(', ')}</td>
                    <td>
                      {product.images && product.images.length > 0 ? (
                        <img src={product.images[0]} alt={product.name} className="product-thumbnail" />
                      ) : (
                        'No Image'
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="no-data">No products found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ManageProducts;
