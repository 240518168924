// src/pages/ManageUsers.js
import React, { useEffect, useState } from 'react';
import { collection, getDocs, updateDoc, doc, deleteDoc, query, where } from 'firebase/firestore';
import { db } from '../firebase-config';
import { ROLES, PERMISSIONS, PERMISSIONS_LIST } from '../constants/adminConstants';
import MultiSelectDropdown from '../components/MultiSelectDropdown/MultiSelectDropdown';
import { useToast } from '../context/ToastContext';
import useAdminRole from '../hooks/useAdminRole';
import './ManageUsers.css';
import { useNavigate } from 'react-router-dom';
import SkeletonUserCard from '../components/Skeleton/SkeletonUserCard';
import SkeletonTable from '../components/Skeleton/SkeletonTable';

const ManageUsers = () => {
  const toast = useToast();
  const { role } = useAdminRole();
  const [activeTab, setActiveTab] = useState('customers');
  const [adminUsers, setAdminUsers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingUserId, setEditingUserId] = useState(null);
  const [editData, setEditData] = useState({ 
    role: '', 
    permissions: []
  });
  const [customerEditData, setCustomerEditData] = useState(null);
  const navigate = useNavigate();

  // Fetch admin users from Firestore
  useEffect(() => {
    const fetchAdminUsers = async () => {
      try {
        const usersCollection = collection(db, 'admin_users');
        const userSnapshot = await getDocs(usersCollection);
        const userList = userSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setAdminUsers(userList);
      } catch (error) {
        console.error("Error fetching admin users:", error);
        toast.error('Failed to fetch admin users');
      }
    };

    const fetchCustomers = async () => {
      try {
        const customersCollection = collection(db, 'users');
        const customerSnapshot = await getDocs(customersCollection);
        const customerList = customerSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setCustomers(customerList);
      } catch (error) {
        console.error("Error fetching customers:", error);
        toast.error('Failed to fetch customers');
      } finally {
        setLoading(false);
      }
    };

    fetchAdminUsers();
    fetchCustomers();
  }, []);

  // Handle customer edit
  const handleCustomerEdit = async (customerId) => {
    try {
      const customerDoc = doc(db, 'users', customerId);
      await updateDoc(customerDoc, customerEditData);
      setCustomers(customers.map(customer => 
        customer.id === customerId ? { ...customer, ...customerEditData } : customer
      ));
      setCustomerEditData(null);
      toast.success('Customer updated successfully');
    } catch (error) {
      console.error("Error updating customer:", error);
      toast.error('Failed to update customer');
    }
  };

  // View customer profile
  const handleViewCustomerProfile = (customerId) => {
    navigate(`/admin/customer-profile/${customerId}`);
  };

  // Handle role and permissions change
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    if (name === 'permissions') {
      // For multi-select, e.target.value is already an array of selected values
      setEditData({ ...editData, permissions: Array.from(e.target.selectedOptions, option => option.value) });
    } else {
      setEditData({ ...editData, [name]: value });
    }
  };

  // Handle permission checkbox change
  const handlePermissionChange = (permission) => {
    setEditData(prev => {
      const newPermissions = prev.permissions.includes(permission)
        ? prev.permissions.filter(p => p !== permission)
        : [...prev.permissions, permission];
      return { ...prev, permissions: newPermissions };
    });
  };

  // Save changes to the user
  const saveChanges = async (userId) => {
    try {
      const userDoc = doc(db, 'admin_users', userId);
      await updateDoc(userDoc, { role: editData.role, permissions: editData.permissions });
      setAdminUsers(adminUsers.map(user => user.id === userId ? { ...user, role: editData.role, permissions: editData.permissions } : user));
      setEditingUserId(null);
      toast.success('User updated successfully');
    } catch (error) {
      console.error("Error updating user:", error);
      toast.error('Failed to update user');
    }
  };

  // Add delete user function
  const handleDeleteUser = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user? This action cannot be undone.')) {
      try {
        const userDoc = doc(db, 'admin_users', userId);
        await deleteDoc(userDoc);
        setAdminUsers(adminUsers.filter(user => user.id !== userId));
        toast.success('User deleted successfully');
      } catch (error) {
        console.error("Error deleting user:", error);
        toast.error('Failed to delete user');
      }
    }
  };

  const renderLoading = () => {
    if (window.innerWidth <= 768) {
      return (
        <>
          {[1,2,3,4].map(i => (
            <SkeletonUserCard key={i} />
          ))}
        </>
      );
    }
    return <SkeletonTable rows={4} columns={5} />;
  };

  if (loading) return <div>Loading users...</div>;

  return (
    <div className="admin-container">
      <div className="page-header">
        <h2 className="admin-header-title">Manage Users</h2>
      </div>

      {loading ? renderLoading() : (
        <div className="manage-users">
          {role === ROLES.ADMIN && (
            <div className="tabs">
              <button 
                className={`tab ${activeTab === 'customers' ? 'active' : ''}`}
                onClick={() => setActiveTab('customers')}
              >
                Customers
              </button>
              <button 
                className={`tab ${activeTab === 'admin' ? 'active' : ''}`}
                onClick={() => setActiveTab('admin')}
              >
                Admin Users
              </button>
            </div>
          )}

          <div className="table-container">
            {activeTab === 'customers' ? (
              // Customers Table
              <table className="users-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {customers.map(customer => (
                    <tr key={customer.id}>
                      <td data-label="Name">{customer.billingName}</td>
                      <td data-label="Email">{customer.email}</td>
                      <td data-label="Mobile">{customer.mobileNumber}</td>
                      <td data-label="Actions">
                        <button 
                          onClick={() => handleViewCustomerProfile(customer.id)}
                          className="btn-view"
                        >
                          View Profile
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              // Admin Users Table (existing table code)
              <table className="users-table">
                <thead>
                  <tr>
                    <th>User ID</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Permissions</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {adminUsers.map(user => (
                    <tr key={user.id}>
                      <td data-label="User ID">{user.id}</td>
                      <td data-label="Email">{user.email}</td>
                      <td data-label="Role">
                        {editingUserId === user.id ? (
                          user.role === ROLES.ADMIN ? (
                            user.role // If user is admin, just show text
                          ) : (
                            <select
                              name="role"
                              value={editData.role}
                              onChange={handleEditChange}
                            >
                              <option value={ROLES.STAFF}>{ROLES.STAFF}</option>
                              <option value={ROLES.VIEWER}>{ROLES.VIEWER}</option>
                            </select>
                          )
                        ) : (
                          user.role
                        )}
                      </td>
                      <td data-label="Permissions">
                        {editingUserId === user.id ? (
                          <MultiSelectDropdown
                            options={PERMISSIONS_LIST}
                            selected={editData.permissions}
                            onChange={handlePermissionChange}
                            disabled={user.role === ROLES.ADMIN}
                          />
                        ) : (
                          user.permissions.join(', ')
                        )}
                      </td>
                      <td data-label="Actions">
                        {editingUserId === user.id ? (
                          <button onClick={() => saveChanges(user.id)} className="btn-save">Save</button>
                        ) : (
                          user.role === ROLES.ADMIN ? (
                            // Don't show edit button for admin users
                            <span className="admin-note">Admin user</span>
                          ) : (
                            <div className="action-buttons">
                              <button 
                                onClick={() => {
                                  setEditingUserId(user.id);
                                  setEditData({ role: user.role, permissions: user.permissions });
                                }} 
                                className="btn-edit"
                              >
                                Edit
                              </button>
                              <button 
                                onClick={() => handleDeleteUser(user.id)} 
                                className="btn-delete"
                              >
                                Delete
                              </button>
                            </div>
                          )
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageUsers;
