import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastProvider } from './context/ToastContext';
import { HelmetProvider } from 'react-helmet-async';
import AdminRoutes from './routes/AdminRoutes';
import UserRoutes from './routes/UserRoutes';
import Navbar from './components/Navbar/Navbar';
import useAdminRole from './hooks/useAdminRole';
import Login from './pages/Login/Login';
import Invoice from './admin/Invoice';
import RenderFooter from './components/Footer/RenderFooter';
import FAQ from './pages/FAQ/FAQ';
import RefundPolicy from './pages/Refund/RefundPolicy';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';

function App() {
  const { role } = useAdminRole();

  return (
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <ToastProvider>
          <Router>
            {/* Remove the extra div wrapper for admin routes */}
            {role === 'admin' || role === 'staff' ? (
              <Routes>
                <Route path="/admin/*" element={<AdminRoutes />} />
                <Route path="/login" element={<Login />} />
                <Route path="/invoice/:invoiceId" element={<Invoice />} />
              </Routes>
            ) : (
              <div>
                <Navbar />
                <Routes>
                  <Route path="/*" element={<UserRoutes />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/invoice/:invoiceId" element={<Invoice />} />
                  <Route path="/faq" element={<FAQ />} />
                  <Route path="/refund-policy" element={<RefundPolicy />} />
                </Routes>
                <RenderFooter />
              </div>
            )}
          </Router>
        </ToastProvider>
      </HelmetProvider>
    </ThemeProvider>
  );
}

export default App;
