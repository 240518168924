import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, CardMedia, CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase-config";
import { collection, getDocs } from "firebase/firestore";
import { generateSlug } from '../../utils/urlUtils';

const ProductCard = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const fetchProducts = async () => {
        setLoading(true);
        try {
          const productsSnapshot = await getDocs(collection(db, "parent_products"));
          const productList = productsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setProducts(productList);
        } catch (error) {
          console.error("Error fetching products:", error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchProducts();
    }, []);

  const navigate = useNavigate();

  const handleCardClick = (product) => {
    const urlPath = product.slug || product.id;
    navigate(`/product/${urlPath}`);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ minHeight: 'calc(100vh - 200px)', }}>
        <Box
        sx={{
          textAlign: "center",
          mt: {
            xs: 2,
            sm: 4
        },
          color: "#33387C",
          fontFamily: "Roboto, sans-serif",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            mb: {
                xs: 1,
                sm: 2
            },
            textTransform: "uppercase",
            letterSpacing: 2,
            fontSize: {
                xs: "1.25rem", // Smaller font size for mobile devices
                sm: "2rem",   // Slightly larger for tablets
                md: "2.5rem", // Default font size for larger screens
            },
          }}
        >
          Explore Our Products
        </Typography>
      </Box>
      <Box
      sx={{
        display: "grid",
        gap: 2,
        gridTemplateColumns: {
          xs: "repeat(2, 1fr)", // 2 cards per row on mobile
          sm: "repeat(3, 1fr)", // 3 cards per row on tablets
          md: "repeat(4, 1fr)", // 4 cards per row on desktop
        },
        marginTop: "15px",
        padding: "10px"
      }}
    >
      {products.map((product) => (
        <Card
          key={product.id}
          onClick={() => handleCardClick(product)}
          sx={{
            cursor: "pointer",
            transition: "transform 0.2s",
            "&:hover": {
              transform: "scale(1.05)",
            },
            height:"270px",
          }}
        >
          <CardMedia
            component="img"
            height="60%"
            image={product.images?.[0] || ''}
            alt={product.name}
            sx={{
                height: {
                    xs: "50%",
                    sm: "60%",
                }
            }}
          />
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              {product.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
            {product.description.length > 60 
                ? `${product.description.substring(0, 60)}...` 
                : product?.description || ''}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
    </Box>
  );
};

export default ProductCard;
