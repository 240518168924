import React from 'react';
import Skeleton from './Skeleton';
import './SkeletonUserCard.css';

const SkeletonUserCard = () => {
  return (
    <div className="skeleton-user-card">
      <div className="skeleton-user-header">
        <div className="skeleton-user-avatar">
          <Skeleton variant="circular" width={40} height={40} />
        </div>
        <div className="skeleton-user-info">
          <Skeleton width={150} height={20} />
          <Skeleton width={120} height={16} />
        </div>
      </div>
      <div className="skeleton-user-details">
        <div className="skeleton-detail-item">
          <Skeleton width={80} height={16} />
          <Skeleton width={120} height={16} />
        </div>
        <div className="skeleton-detail-item">
          <Skeleton width={60} height={16} />
          <Skeleton width={100} height={16} />
        </div>
      </div>
      <div className="skeleton-user-actions">
        <Skeleton width={100} height={36} />
      </div>
    </div>
  );
};

export default SkeletonUserCard; 