import React, { useState } from 'react';

const ProductInformation = ({ product, productId }) => {
  const [selectedImage, setSelectedImage] = useState(product.images?.[0] || '');

  return (
    <div className="product-summary card">
      <h3>Product Information</h3>
      <div className="product-info-grid">
        <div className="info-item">
          <strong>Product ID:</strong> {productId}
        </div>
        <div className="info-item">
          <strong>Name:</strong> {product.name}
        </div>
        <div className="info-item">
          <strong>HSN Code:</strong> {product.hsn}
        </div>
        <div className="info-item">
          <strong>GST:</strong> {product.gst}%
        </div>
        <div className="info-item">
          <strong>Weight:</strong> {product.weight}g
        </div>
        <div className="info-item">
          <strong>Categories:</strong> {product.categories.join(', ')}
        </div>
      </div>
      <div className="product-description">
        <strong>Description:</strong>
        <p>{product.description}</p>
      </div>
      <div className="product-images">
        <div className="main-image">
          {selectedImage && <img src={selectedImage} alt={product.name} />}
        </div>
        {product.images && product.images.length > 0 && (
          <div className="image-thumbnails">
            {product.images.map((imageUrl, index) => (
              <div 
                key={index} 
                className={`thumbnail ${selectedImage === imageUrl ? 'active' : ''}`}
                onClick={() => setSelectedImage(imageUrl)}
              >
                <img src={imageUrl} alt={`${product.name} ${index + 1}`} />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductInformation; 