import React from 'react';
import Skeleton from './Skeleton';
import './SkeletonOrderCard.css';

const SkeletonOrderCard = () => {
  return (
    <div className="skeleton-order-card">
      <div className="skeleton-order-header">
        <Skeleton className="skeleton-order-id" width={120} height={24} />
        <Skeleton className="skeleton-order-date" width={100} height={20} />
      </div>
      
      <div className="skeleton-order-details">
        <div className="skeleton-detail-row">
          <Skeleton width={80} height={16} />
          <Skeleton width={150} height={16} />
        </div>
        <div className="skeleton-detail-row">
          <Skeleton width={60} height={16} />
          <Skeleton width={100} height={16} />
        </div>
        <div className="skeleton-detail-row">
          <Skeleton width={70} height={16} />
          <Skeleton width={120} height={16} />
        </div>
      </div>

      <div className="skeleton-order-footer">
        <Skeleton className="skeleton-status" width={100} height={32} />
        <Skeleton className="skeleton-action" width={120} height={36} />
      </div>
    </div>
  );
};

export default SkeletonOrderCard; 