import React from 'react';
import Skeleton from './Skeleton';
import './SkeletonTable.css';

const SkeletonTable = ({ rows = 5, columns = 4 }) => {
  return (
    <div className="skeleton-table">
      {/* Header */}
      <div className="skeleton-table-header">
        {Array(columns).fill().map((_, i) => (
          <Skeleton 
            key={`header-${i}`}
            className="skeleton-header-cell"
            height={24}
          />
        ))}
      </div>

      {/* Rows */}
      {Array(rows).fill().map((_, rowIndex) => (
        <div key={`row-${rowIndex}`} className="skeleton-table-row">
          {Array(columns).fill().map((_, colIndex) => (
            <Skeleton 
              key={`cell-${rowIndex}-${colIndex}`}
              className="skeleton-table-cell"
              height={20}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default SkeletonTable; 