import React from 'react';
import { signOut } from 'firebase/auth';
import { auth } from '../../../firebase-config';
import { useNavigate } from 'react-router-dom';
import { 
  Menu as MenuIcon, 
  AccountCircle,
  Notifications,
  Search
} from '@mui/icons-material';
import './Header.css';

const Header = ({ onMenuClick }) => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <header className="admin-header">
      <div className="header-left">
        <button className="menu-toggle" onClick={onMenuClick}>
          <MenuIcon />
        </button>
        <div className="search-bar">
          <Search />
          <input type="text" placeholder="Search..." />
        </div>
      </div>
      <div className="header-right">
        <button className="header-icon-btn">
          <Notifications />
          <span className="notification-badge">3</span>
        </button>
        <div className="user-menu">
          <AccountCircle />
          <button onClick={handleLogout} className="logout-btn">
            Logout
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header; 